




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as api from '../api'
import * as model from '../models'
import { ListSell } from '@/modules/common/models'
import listSell from '@/modules/common/components/list-sell.vue'
import dsPagination from '@/modules/common/components/ds-pagination.vue'
import contentBox from '@/modules/common/components/content-box.vue'

@Component({
  components: {
    listSell,
    dsPagination,
    contentBox
  }
})
export default class ListCourse extends Vue {
  @Prop({ default: '' }) type!: string
  @Watch('$route')
  onRouteChange(to: any, from: any) {
    this.renderCourseList()
  }

  courseStyle: object = {
    'width': '100%',
    'display': 'flex',
    'flex-direction': 'column',
    'align-items': 'center'
  }
  isLoading: boolean = true
  offlineList: Array<model.OfflineList> = []
  total: number = 0
  page: number = 1
  count: number = 10
  title: string = '课程'
  mounted() {
    window.scrollTo(0, 0)
    this.renderCourseList()
  }

  get isHaveData() {
    return this.offlineList.length
  }

  get getMoreTips() {
    return `全部${this.title ? this.title : '课程'} (${this.total})`
  }

  renderCourseList() {
    this.page = Number(this.$route.query.page) || 1
    this.count = Number(this.$route.query.count) || 10
    this.title = this.$route.query.title && String(this.$route.query.title)
    this.getOfflineCourseList()
  }

  getOfflineCourseList() {
    this.isLoading = true
    api.offlineList({
      page: this.page,
      size: this.count
    }).then(res => {
      this.offlineList = res.data
      this.total = res.page.total
      this.isLoading = false
    }).catch(res => {
      this.offlineList = []
      this.total = 0
      this.isLoading = false
      console.error('getOfflineCourseList = ', res)
    })
  }

  getListSellItem(item: model.OfflineList) {
    let listSellItem: ListSell = {
      content_id: item.id,
      type: 'offlinecourse',
      title: item.name,
      brief: item.brief,
      indexpic: item.cover_image,
      highest_price: Number(item.highest_price / 100),
      lowest_price: Number(item.lowest_price / 100),
      subscribe_count: item.total_sales_quantity,
      join_membercard: 0,
      original_price: Number(item.lowest_original_price / 100),
      highest_original_price: Number(item.highest_original_price / 100),
      update_time: ''
    }
    return listSellItem
  }

  onPageChange(val: number) {
    this.$router.push(({
      name: this.$route.name,
      query: {
        page: `${val}`,
        count: `${this.count}`,
        title: `${this.title}`
      }
    } as any))
  }
}
